// jQuery(document).ready(function($){
// 	$('.homepage_carousel').slick({
// 		slidesToShow: 1,
// 		slidesToScroll: 1,
// 		autoplay: true,
// 		autoplaySpeed: 8000,
//   		dots: true,
//   		appendDots: $('.carousel_wrap')
// 	});
// });

// jQuery(document).ready(function($){
// 	$('.card_carousel').slick({
// 		autoplay: true,
// 		autoplaySpeed: 4000,
// 		infinite: true,
// 		slidesToShow: 4,
// 		slidesToScroll: 2,
// 		dots: true,
// 		arrows: false,
// 		responsive: [
//     	{
//     	breakpoint: 1199,
//       	settings: {
//         	slidesToShow: 3,
//         	slidesToScroll: 1,
// 			dots: true,
// 			autoplaySpeed: 3000,
//       		}
//     	},
//     	{
// 		breakpoint: 991,
//       	settings: {
//         	slidesToShow: 2,
//         	slidesToScroll: 1,
// 			dots: true,
// 			autoplaySpeed: 3000,
//       		}
//     	},
// 		{
// 		breakpoint: 520,
//       	settings: {
//         	slidesToShow: 1,
//         	slidesToScroll: 1,
// 			dots: true,
// 			autoplaySpeed: 2500,
//       		}
//     	},
//  		]
// 	});
// });

jQuery(document).ready(function($){
	jQuery('.slide').hover(
		function(){
			$(this).find('.cards').addClass('flip');
		},function(){
			$(this).find('.cards').removeClass('flip');
		}
	);
});

jQuery(document).ready(function($){
	jQuery('#service_cards .column').hover(
		function(){
			$(this).find('.cards').addClass('flip');
		},function(){
			$(this).find('.cards').removeClass('flip');
		}
	);
});

jQuery(document).ready(function($){
	$('.associate_cards .cols').hover(
		function(){
			$(this).find('.cards').addClass('flip');
		},function(){
			$(this).find('.cards').removeClass('flip');
		}
	);
});

jQuery(document).ready(function($){
	$('.associate_btn').click(function(){
		id = $(this).data('id');
		$('#' + id).addClass('active');
		// $('.associate_descrip').removeClass("hide");
		$('body').addClass('no_scroll');
	});
});

jQuery(document).ready(function(){
	$('.associate_descrip .close_modal').click(function(){
		$(this).closest('.associate_descrip').removeClass('active');
		// $('.associate_descrip').addClass("hide");
		$('body').removeClass('no_scroll');
	});
});



// browser detections - not working

jQuery(document).ready(function($){
function msieversion() 
{
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > -1) // If Internet Explorer, return version number
    {
        document.body.classList.add("ie");
    }
    else  // If another browser, return 0
    {
        document.body.classList.add("other");
    }

    return false;
	};
});



//preloader start

$(document).ready(function() {
  setTimeout(function() {
    $('.prewrapper').addClass('loaded');
    
  }, 300);
});



jQuery(function(){

  $(window).load(function(){
  $('.prewrapper').removeClass('preload');

  });

});


//preloader end

// browser detection
jQuery(document).ready(function(){
	ShowIEAlert();

	function isIE(){
	    var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
	    var msie = ua.indexOf('MSIE '); // IE 10 or older
	    var trident = ua.indexOf('Trident/'); //IE 11

	    return (msie > 0 || trident > 0);
	}


	//function to show alert if it's IE
	function ShowIEAlert(){
	    if(isIE()){
	       $('body').addClass('browser_version_ie');
	    }else{
	    	$('body').addClass('browser_version_not_ie');
	    }
	}
});
	
// burger nav function

jQuery(document).ready(function($){
	$('#menu_btn').click(function(){
		$('#header_nav').toggleClass('open');
		$('#menu-item-21, #menu-item-237').find('.sub-menu').toggleClass('active');
	});
});




// change container to container fluid on screensize

(function($) {
    var $window = $(window),
        $html = $('.container.fluid-sm');

    function resize() {
        if ($window.width() < 514) {
            return $html.addClass('container-fluid');
		    $html.removeClass('container');        
		}
    }

    $window
        .resize(resize)
        .trigger('resize');
})(jQuery);



// Fixes to make the site look ok on IE

jQuery(document).ready(function($){
    if ($("body").hasClass("browser_version_ie")){
        $(".contact").css({'transform': 'translateY(-50px)'});
        $("#desktop_txt").css({'transform': 'translateY(-65px)'});
        $("#footer_logo").css({'max-height': '100px'});
        $(".page_heading").css({'margin-top': '3rem'});
        $(".social li").css({'max-height': '140px'});
        $(".text").css({'margin-top': '10rem'});
    }
});




//products submenu
jQuery(document).ready(function($){
	// , #menu-item-26, #menu-item-62, #menu-item-61, #menu-item-60, #menu-item-58, #menu-item-180
	jQuery('#menu-item-21, #menu-item-237, #menu-item-26, #menu-item-62, #menu-item-61, #menu-item-60, #menu-item-58, #menu-item-180').hover(
		function(){
			$(this).find('.sub-menu:first').addClass('active');
		},function(){
			$(this).find('.sub-menu:first').removeClass('active');
		}
	);
});


//magnigic popup
jQuery(document).ready(function($){
	$(function() {
	  	//almComplete() is dispatched after every successful Ajax Load More query
		$.fn.almComplete = function(alm){
		    $('.column').magnificPopup({
			  	delegate: 'a',
			    type: 'image',
			    tLoading: 'Loading image #%curr%...',
			    mainClass: 'mfp-img-mobile',

			    gallery: {
			        enabled: true,
			        navigateByImgClick: true,
			        preload: [0,1] // Will preload 0 - before current, and 1 after the current image
			    },

			    image: {
			        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
			        titleSrc: function(item) {
			            return '<h4 class="lb-title">' + item.el.attr('title') + '</h4>' + '<p class="lb-description">' + item.el.attr('data-description') + '</p>';
			        }
			    }
			});
	  	};
	})(jQuery);
});
	

jQuery(document).ready(function($){
	$('#menu_btn').click(function(){
		$('nav').toggleClass('open');
	});
});




































































































